import { helpers } from '@vuelidate/validators'
import { useI18nValidators } from '../utils/validations/i18n-validators'

export class FormService {
    private readonly $translate: any

    constructor() {
        const { $translate } = useNuxtApp()
        this.$translate = $translate
    }

    getVuelidateRules(metaRules: any): object {
        const validators = []
        // Auto create validators data from useI18nValidators
        const validatorsList = useI18nValidators()

        Object.keys(validatorsList).forEach((key) => {
            validators[key] = useI18nValidators()[key]
        })

        /* Pour chaque validations reçues du back, on va générer un objet avec les fonctions des validations traduites dans nuxt/composables/i18n-validators.ts
                    sous ce format : { nom du champ (name) : {
                            required : this.required,
                            email : this.email
                        }
                    }
                    S'il n'y a pas de function correspondante (string), la validation ne s'ajoute pas à l'objet
                */
        const obj = {}
        let globalKeyToInit = []

        if (metaRules) {
            let keys = Object.keys(metaRules)
            keys = keys.filter(key => !metaRules[key].includes('noFrontValidation'))
            //  split when * and filter contacts to create object with last elem)

            keys.forEach((key) => { // key = contacts.*.email
                let splitted, globalKey, dataKey

                if (key.includes('*')) { // IF ARRAY
                    splitted = key.split('.')
                    globalKey = splitted[0] // contacts
                    dataKey = splitted[splitted.length - 1] // name

                    globalKeyToInit.push(globalKey)

                    if (!obj[globalKey]) {
                        obj[globalKey] = {}
                    }
                    if (!obj[globalKey].each) {
                        obj[globalKey].each = {}
                    }
                }
                else if (key.includes('.')) { // IF OBJECT
                    splitted = key.split('.')
                    globalKey = splitted[0] // contacts
                    dataKey = splitted[splitted.length - 1] // name

                    globalKeyToInit.push(globalKey)

                    if (!obj[globalKey]) {
                        obj[globalKey] = {}
                    }
                    // if (!obj[globalKey].each) {
                    //     obj[globalKey].each = {}
                    // }
                }

                const validations = {}

                metaRules[key].forEach((ruleKey) => { // ruleKey = required
                    let updatedRuleKey = ruleKey

                    if (ruleKey.includes('regex:')) {
                        updatedRuleKey = 'regex'
                    }

                    if (ruleKey.includes('min:')) {
                        updatedRuleKey = 'minLength'
                        // validators.minLength.$validator(2)

                        if (metaRules[key].includes('integer')) { // IF RULES IS INT -> MAX / MIN IS VALUES, NOT LENGTH
                            updatedRuleKey = 'minValue'
                        }
                    }
                    if (ruleKey.includes('max:')) {
                        // validators.minLength.$validator(2)
                        updatedRuleKey = 'maxLength'

                        if (metaRules[key].includes('integer')) { // IF RULES IS INT -> MAX / MIN IS VALUES, NOT LENGTH
                            updatedRuleKey = 'maxValue'
                        }
                    }

                    if (validators[updatedRuleKey]) {

                       if (updatedRuleKey === 'regex') {
                            // OVERRIDE REGEX FUNCTION FROM i18n-validators to make it work
                           const splittedRegex = ruleKey.split(':') // ['regex', '/[abc]+/g']
                           const regexString = splittedRegex[splittedRegex.length - 1]
                           const lastSlash = regexString.lastIndexOf("/");
                           const regexValue = new RegExp(regexString.slice(1, lastSlash), regexString.slice(lastSlash + 1))
                           console.log('splittedRegex', splittedRegex, regexValue)

                            validations[updatedRuleKey] = {
                                $validator: helpers.regex(regexValue),
                                $message: this.$translate(`forms.validations.${updatedRuleKey}`), // , { value: splittedMaxMin[splittedMaxMin.length - 1] }
                            }

                        }
                        // SETUP VALIDATION
                        else if (updatedRuleKey === 'minLength' || updatedRuleKey === 'maxLength' || updatedRuleKey === 'minValue' || updatedRuleKey === 'maxValue') {
                            const splittedMaxMin = ruleKey.split(':')
                            validations[updatedRuleKey] = validators[updatedRuleKey].$validator(splittedMaxMin[splittedMaxMin.length - 1])
                            // SETUP TRANSLATE HERE BECAUSE CANT MAKE IT WORK FROM i18n-validators ATM
                            validations[updatedRuleKey] = {
                                ...validations[updatedRuleKey],
                                $message: this.$translate(`forms.validations.${updatedRuleKey}`, { value: splittedMaxMin[splittedMaxMin.length - 1] }),
                            }
                        }
                        else {
                            validations[updatedRuleKey] = validators[updatedRuleKey]
                        }
                        // ASSIGN VALIDATION
                        if (dataKey && obj[globalKey].each) {
                            obj[globalKey].each[dataKey] = validations
                        }
                        else if (dataKey) {
                            obj[globalKey][dataKey] = validations
                        }
                        else {
                            // OVERRIDE REQUIRED IF array IS IN RULES TO SETUP MINLENGTH(1) : required blocks validation on array
                            obj[key] = validations
                        }
                    }
                })
            })
        }
        globalKeyToInit = [...new Set(globalKeyToInit)]
        globalKeyToInit.forEach((key) => {
            if (obj[key]) {
                if (key) {
                    obj[key] = {
                        ...obj[key],
                        // minLength : validators.minLength.$validator(2),

                        $each: helpers.forEach({
                            ...obj[key].each,
                        }),
                    }
                }

                if (!obj[key].each) {
                    delete obj[key].$each
                }
                delete obj[key].each
            }
        })

        return { data: { ...obj } }
    }
}
